<template>
  <div class="form">
    <div class="form-group row">
      <div class="col-md-4">
        <label class="col-md-12">Seleccione el producto :*</label>
        <treeselect v-model="produto_id" :multiple="false" :options="lista_produtos" />
      </div>
      <div class="col-md-4">
        <label class="col-md-12">Selecione o Estoque :*</label>
        <select required type="text" class="form-control" v-model="form.estoque_produto_id"
          placeholder="Digite Tipo de Produto">
          <option v-for="lista in lista_produtos_estoque" :key="lista.id" :value="lista.id">
            Lote: {{ lista.lote }} - {{ lista.validade }} -
            {{ lista.estoque_fracao }}
          </option>
        </select>
      </div>
      <div class="col-md-4">
        <label class="col-md-12">Lotes:</label>

        <b-input-group class="mb-2 mr-sm-2 mb-sm-0 bg-secondary">
          <input required type="text" class="form-control" v-model="nome_pesquisa" @click="pesquisar()" />
          <b-button v-b-modal.SelecionarLote class="btn btn-indo">
            <i class="fa fa-search search-icon m-0 p-0"></i>
          </b-button>
        </b-input-group>

        <div>
          <b-modal size="lg" id="SelecionarLote" hide-footer hide-header title="SelecionarLote" ref="modal_lote">
            <input required type="text" class="form-control" v-model="nome_pesquisa" />
            <b-table :fields="['lote', 'produto', 'validade', 'fracao', 'acoes']" :items="lista_estoque_produtos"
              :per-page="perPage" :current-page="currentPage" id="atendimento-table"
              class="table table-hover table-responsive" show-empty empty-text="Nenhum registro encontrado!">
              <template #cell(acoes)="{ item }">
                <button @click="selecionarEstoque(item)" class="btn btn-light mx-1" v-b-tooltip.hover>
                  Selecionar
                </button>
              </template>
            </b-table>
            <b-pagination v-model="currentPage" :total-rows="lista_estoque_produtos.length" :per-page="perPage"
              aria-controls="atendimento-table">
            </b-pagination>
          </b-modal>
        </div>
      </div>

    </div>

    <div class="form-group row">
      <div class="col-md-4">
        <label class="col-md-12"> Quantidade:*</label>
        <input required type="number" class="form-control" v-model="form.quantidade" placeholder="Digite o nome..." />
      </div>

      <div class="col-md-8">
        <label class="col-md-12"> Descrição:</label>
        <input required type="text" class="form-control" v-model="form.descricao" placeholder="Digite o nome..." />
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-12 text-center">
        <button @click.prevent="confirm(saida_id != '' ? 'edita' : 'cria')" class="btn btn-primary" :disabled="verif">
          {{ $t("SALVAR.NOME") }}
          <b-spinner v-show="verif" small variant="dark" type="grow" label="Spinning"></b-spinner>
        </button>
      </div>
    </div>




  </div>
</template>
<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import ApiService from "@/core/services/api.service";
import { isThisWeek } from "date-fns";

export default {
  mixins: [fireAlert],
  data() {
    return {
      currentPage: 2,
      perPage: 10,
      selectedProduto: null,
      form: {
        estoque_produto_id: null,
        quantidade: null,
        paciente_id: null,
        ocorrencia_id: null,
        descricao: null,
        tipo_saida_id: 1,
      },
      nome_pesquisa: null,
      produto_id: null,
      saida_id: "",
      verif: false,
      // lista_campos:""
    };
  },
  created() {
    this.listar_dados();
    console.log(this.lista_campos);
  },

  computed: {
    lista_produtos() {
      return this.$store.state.produto.lista_produtos;
    },
    lista_produtos_estoque() {
      return this.$store.state.produto.lista_produtos_estoque;
    },
    lista_usuarios() {
      return this.$store.state.configUsuarios.lista_usuarios;
    },
    mensagem_alert() {
      return this.$store.state.produto.mensagem_alert;
    },
    lista_filials() {
      return this.$store.state.configEmpresa.lista_filials;
    },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    lista_saida_produtos() {
      return this.$store.state.produto.lista_saida_produtos;
    },
    lista_estoque_produtos() {
      return this.$store.state.produto.lista_estoque_produtos;
    },
  },
  created(){
    this.listar_dados()
    console.log(this.lista_campos)
    if(this.lista_campos != ''){
      this.produto_id = this.lista_campos.produto_id
    }
  },

  watch: {
    produto_id() {
      console.log(this.produto_id);
      this.listar_estoque();
    },
    nome_pesquisa() {
      if (this.nome_pesquisa.length >= 3) {
        var p = { texto: this.nome_pesquisa, tipo: "local" };
        this.$store.dispatch("produto/pesquisar_estoque_por_lotes", p);
      }
      if (this.nome_pesquisa.length == 0) {
        this.form.estoque_produto_id = null;
        this.produto_id = null;
      }
    },
  },
  methods: {
    async listar_dados() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      // await this.$store.dispatch("configUsuarios/listar_usuarios", 3);
      await this.$store.dispatch("produto/listar_produto_ativo");
      const { saida_produto_id } = this.$route.params;
      if (saida_produto_id) {
        await this.$store.dispatch(
          "produto/get_saida_produto",
          saida_produto_id
        );
        await this.preenxerCampos();
      }
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },
    selecionarEstoque(item) {
      this.form.estoque_produto_id = item.id;
      this.produto_id = item.produto_id;
      this.$refs["modal_lote"].hide();
    },
    pesquisar() {
      this.$store.dispatch(
        "produto/pesquisar_estoque_por_lotes",
        this.nome_pesquisa
      );
    },
    async listar_estoque() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch(
        "produto/listar_estoques_disponiveis",
        this.produto_id
      );
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },

    async confirm(tipo) {

      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` uma saida no sistema.`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") {
              this.create();
              this.limpar_campos()
            }
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      this.form.ocorrencia_id = this.lista_campos.ocorrencia_id;
      console.log(this.form);
      await this.$store.dispatch("produto/create_saida_produto", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
      });
    },
    async update() {
      this.verif = true;
      var dados = {
        form: this.form,
        id: this.saida_id,
      };
      this.form.id = this.saida_id;
      console.log(this.form);
      await this.$store.dispatch("produto/update_saida_produto", dados);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "saidaProduto",
      });
    },

    async preenxerCampos() {
      this.saida_id = this.lista_saida_produtos.id;
      this.form.estoque_produto_id =
        this.lista_saida_produtos.estoque_produto_id;
      this.produto_id = this.lista_saida_produtos.produto_id;
      this.form.quantidade = this.lista_saida_produtos.quantidade;
      this.form.receita_id = this.lista_saida_produtos.receita_id;
      this.form.paciente_id = this.lista_saida_produtos.paciente_id;
      this.form.descricao = this.lista_saida_produtos.descricao;
    },
    async limpar_campos() {
      this.form.estoque_produto_id = null
      this.form.quantidade = null
      this.form.receita_id = null
      this.form.paciente_id = null
      this.form.descricao = null
      this.form.produto_id = null

    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>