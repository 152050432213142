<template>
  <div class="col-md-12">
    <div class="form-group row justify-content-center">
      <div v-if="lista_campos.nome_sobrenome" class="col-md-12 mt-2">
        Medico(a):{{ lista_campos.nome_sobrenome }}
      </div>

      <div class="col-md-12 mt-2">
        <div class="table-responsive">
          <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
            <thead>
              <tr class="fw-bold fs-6 text-gray-800">
                <th>Medicamento</th>
                <th>Via Administración</th>
                <th>Instruciones</th>
                <th>En stock?</th>
                <th>
                  <button v-if="lista_campos == ''" @click="add(true)" title="Agregar Medicamento "
                    class="btn btn-icon btn-success float-right btn-sm mx-1">
                    <span class="fas fa-plus"></span>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(lista, index) in form.produtos_receita" :key="index">
                <td>{{ lista.medicamento }}</td>
                <td>{{ lista.via_administracao }}</td>
                <td>{{ lista.instrucao }}</td>
                <td v-if="lista.produto_id != null">si</td>
                <td v-if="lista.produto_id == null">no</td>
                <td class="text-right">
                  <button v-if="lista_campos == ''" @click="confirm('exclui', index)"
                    class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover title="Retirar ">
                    <i class="ki ki-close icon-xs text-danger"></i>
                  </button>
                  <button v-if="lista_campos != ''" @click="visualizar(lista)"
                    class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover title="Realizar salida">
                    <i class="fas fa-tablets icon-lg text-info"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <form>
      <div v-if="add_med == true" class="form-group row">
        <div class="col-md-6"></div>
        <div class="col-md-6 text-right">
          <div class="form-check ">
            <input type="checkbox" class="form-check-input" id="checkbox" v-model="nao_encontrou_medicamento">
            <label class="form-check-label" for="checkbox">No encontré la medicación</label>
          </div>
        </div>
        <div class="col-md-2 mt-2" v-if="nao_encontrou_medicamento == false">
          <label class="col-md-12">Classe:*</label>
          <treeselect v-model="tipo_classe_id" :multiple="false" :options="lista_tipos_classe" />
        </div>
        <div class="col-md-10 mt-2" v-if="nao_encontrou_medicamento == false">
          <label class="col-md-12">Producto:*</label>
          <treeselect v-model="produto_id" :multiple="false" :options="lista_produtos" />
        </div>
      </div>
      <div v-if="add_med == true && nao_encontrou_medicamento == true" class="form-group row">
        <div class="col-md-6 mt-2">
          <label class="col-md-12">Medicamento:*</label>
          <input required type="text" class="form-control" v-model="itens.medicamento" />
        </div>
        <div class="col-md-6 mt-2">
          <label class="col-md-12">{{ $t("ADMIN.NOME") }}</label>
          <input required type="text" class="form-control" v-model="itens.via_administracao" />
        </div>
      </div>

      <div v-if="add_med == true && nao_encontrou_medicamento == false" class="form-group row">
        <div class="col-md-6">
          <label class="col-md-12">{{ $t("ADMIN.NOME") }}</label>
          <input required type="text" class="form-control" v-model="itens.via_administracao" />
        </div>
        <div class="col-md-3">
          <label class="col-md-12">Cantidad en stock:</label>
          <input type="text" class="form-control" disabled v-model="produto_selecionado.filial_quantidade" />
        </div>
        <div class="col-md-3">
          <label class="col-md-12">Cantidad en Fracción:</label>
          <input type="text" class="form-control" disabled v-model="produto_selecionado.filial_fracao" />
        </div>
      </div>
      <div v-if="add_med == true" class="form-group row justify-content-center">
        <div class="col-md-12 mt-2">
          <label class="col-md-12">{{ $t("INSTRU.NOME") }}</label>
          <input required type="text" class="form-control" v-model="itens.instrucao" />
        </div>
        <div class="col-md-12 mt-2 justify-content-center">
          <div class="w-100">
            <div class="text-center">
              <div class="col-md-12">
                <button @click="add_medicamento()" class="btn btn-sm btn-success" :disabled="verif">
                  {{ $t("GERENCIAR.NOME") }}
                  <b-spinner v-show="verif" small variant="dark" type="grow" label="Spinning"></b-spinner>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="form-group row justify-content-center" >
      <div class="col-md-11 mt-2 text-center">
        <button v-if="lista_campos == ''" @click.prevent="confirm(lista_campos == '' ? 'cria' : 'edita')" class="btn btn-primary float-right"
          :disabled="verif">
          {{ $t("GERENCIAR.NOME") }}
          <b-spinner v-show="verif" small variant="dark" type="grow" label="Spinning"></b-spinner>
        </button>
      </div>
    </div>
    <b-modal v-model="modal_ocorrencia.show" title="Saida" ref="Saida" size="lg" centered hide-footer>
      <b-overlay :show="modal_ocorrencia.loading" rounded="sm">
        <div class="row justify-content-center">
          <saida></saida>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import Saida from "@/view/components/farmacia/saidaProduto/formSaidaReceita.vue";

export default {
  components:{Saida},
  mixins: [fireAlert],
  data() {
    return {
      verif: false,
      add_med: false,
      form: {
        atendimento_id: "",
        descricao: "",
        status: "",
        nome: "",
        produtos_receita: [],
      },
      modal_ocorrencia: {
        show: false,
        loading: false
      },
      produto_selecionado: {},
      tipo_classe_id: null,
      produto_id: null,
      nao_encontrou_medicamento: false,
      itens: {
        medicamento: "",
        via_administracao: "",
        instrucao: "",
        produto_id: null
      },
      currentPage: 1,
      perPage: 10,
    };
  },
  computed: {
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.ocorrencia.mensagem_alert;
    },
    lista_usuarios() {
      return this.$store.state.configUsuarios.lista_usuarios;
    },
    lista_tipos_classe() {
      return this.$store.state.tipoClasse.lista_tipos_classe;
    },
    lista_produtos() {
      return this.$store.state.produto.lista_produtos;
    },

  },
  created() {
    this.listar_filtros()
    this.preenxerCampos();

    // this.listar_usuarios_matriculas();
  },
  watch: {
    lista_campos() {
      this.preenxerCampos();
    },
    tipo_classe_id() {
      this.listar_produtos()
    },
    produto_id() {
      for (let i = 0; i < this.lista_produtos.length; i++) {
        if (this.lista_produtos[i].id == this.produto_id) {
          this.itens.medicamento = this.lista_produtos[i].label
          this.itens.produto_id = this.produto_id
          this.produto_selecionado = this.lista_produtos[i]
        };

      }
    }
  },
  methods: {
    async visualizar(value) {
      this.modal_ocorrencia.loading = true
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("configEmpresa/atualizar", value).finally(() => {
        this.modal_ocorrencia.show = true
        this.modal_ocorrencia.loading = false
        this.$store.dispatch("configEmpresa/MudarPreloader", false)
      });
    },
    add(value) {
      this.add_med = value
      this.nao_encontrou_medicamento = false
      this.tipo_classe_id = null
      this.produto_id = null
    },
    async confirm(tipo, iten) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` uma receita.`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
            if (tipo == "exclui") this.delete(iten);
          }
        },
      });
    },

    async create() {
      const { atendimento_id } = this.$route.params;
      this.form.atendimento_id = atendimento_id;
      let data = { atendimento_id: atendimento_id };
      var msg = {
        tipo: "error",
        message: "Deve-se se adicionar no mínimo um medicamento para salvar",
      };
      if (this.form.produtos_receita.length < 1) this.fireAlert(msg);
      else {
        this.verif = true;
        await this.$store
          .dispatch("ocorrencia/create_receita", this.form)
          .finally(() => {
            this.$store.dispatch(
              "atendimento/lista_atendimento_ocorrencias",
              data
            );
            this.verif = false;
            this.fireAlert(this.mensagem_alert);
            if (this.mensagem_alert.tipo === "error") {
              var dados = {
                tipo: "Receita",
                fechar: false,
              };
            } else {
              var dados = {
                tipo: "Receita",
                fechar: true,
              };
              this.$store.dispatch("ocorrencia/save_dados_modais", dados);
            }
          });
      }
    },

    preenxerCampos() {
      console.log(this.lista_campos)
      if (this.lista_campos != "") var val = this.lista_campos;
      if (val && val.medicamentos_receita) {
        this.form.produtos_receita = val.medicamentos_receita;
      }
    },

    searchFuncionarios({ searchQuery, callback }) {
      let data = this.$store.state.configUsuarios.lista_usuarios.filter(
        (item) => {
          if (!searchQuery) return true;
          return (
            String(item["nome"])
              .toLowerCase()
              .indexOf(searchQuery.toLowerCase()) !== -1
          );
        }
      );

      console.log(data);
      let options = data.map((usuario) => ({
        id: usuario.id,
        label: usuario.nome,
      }));
      callback(null, options);
    },

    add_medicamento() {
      if (this.itens.medicamento == "" || this.itens.instrucao == "") {
        var msg = {
          tipo: "error",
          message: "Os campos medicamentos e intrução devem ser preenchidos",
        };
        this.fireAlert(msg);
      } else {
        var p = {
          medicamento: this.itens.medicamento,
          instrucao: this.itens.instrucao,
          via_administracao: this.itens.via_administracao,
          produto_id: this.produto_id
        };
        this.form.produtos_receita.push(p);
        this.itens.medicamento = "";
        this.itens.instrucao = "";
        this.itens.via_administracao = "";
        this.add_med = false;
      }
    },

    delete(value) {
      this.$delete(this.form.produtos_receita, value);
      console.log(this.form.produtos_receita);
    },

    async listar_filtros() {
      await this.$store.dispatch("tipoClasse/listar_tipos_classe")
    },
    async listar_produtos() {
      const filtro = {
        tipo_classe_id: this.tipo_classe_id
      }
      await this.$store.dispatch("produto/listar_produto_estoque_filial", filtro)
      this.produto_id = null
      this.itens.medicamento = null
      // this.quantidade = null
    },
  },
};
</script>
